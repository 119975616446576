<template>
  <div class='page-box'>
    <base-screen>
      <a-form-model ref="formRef" layout="inline" :model="queryRef" @submit="handleSearch" @submit.native.prevent>
        <a-form-model-item label="时间" prop="dateRange">
          <a-range-picker v-model='queryRef.dateRange' format="YYYY-MM-DD" value-format="YYYY-MM-DD" />
        </a-form-model-item>
        <a-form-model-item>
          <a-button type="primary" @click="handleSearch">查询</a-button>
          <a-button @click="handleReset">重置</a-button>
        </a-form-model-item>
      </a-form-model>
    </base-screen>
    <a-tabs v-model='queryRef.active' @change="changeTab">
      <a-tab-pane :key="0" tab="访问次数Top10">
      </a-tab-pane>
      <a-tab-pane :key="1" tab="购买次数Top10">
      </a-tab-pane>
    </a-tabs>
    <a-table :rowKey="(record,index) => record.productId" :loading='loading' :columns="columns" :data-source="tableData" :pagination="false">
      <template slot="index" slot-scope="text,record,index">
        {{index + 1}}
      </template>
      <template slot="good" slot-scope="text,record">
        <div class='flex-a-c'>
          <base-img class="goods-img" :src="record.mainPicPath" width="60" height="60" oss_style="60_60" />
          <a-button type='link' @click='$router.push(`/goods/goods_detail/${record.productId}`)'>{{record.productName}}</a-button>
        </div>
      </template>
    </a-table>
  </div>

</template>

<script>
import { goods } from '@/api'
export default {
  data: () => ({
    queryRef: {
      dateRange: [],
      active: 0,
    },
    loading: false,
    tableData: [],
    columns: [
      {
        title: '排序',
        dataIndex: 'index',
        scopedSlots: { customRender: 'index' },
      },
      {
        title: '商品',
        dataIndex: 'good',
        scopedSlots: { customRender: 'good' },
      },
      {
        title: '浏览次数',
        dataIndex: 'productAccessRecordNum',
      },
      {
        title: '购买次数',
        dataIndex: 'productBuyNum',
      },
    ],
  }),
  methods: {
    handleSearch () {
      this.getTableData()
    },
    handleReset () {
      this.$refs.formRef.resetFields()
      this.getTableData()
    },
    changeTab () {
      this.getTableData()
    },
    async getTableData () {
      this.loading = true
      const { code, data, msg } = await goods.getGoodsRankPage({
        type: this.queryRef.active,
        beginTime: this.queryRef.dateRange.length ? this.queryRef.dateRange[0] : '',
        endTime: this.queryRef.dateRange.length ? this.queryRef.dateRange[1] : '',
      })
      this.loading = false
      if (code === '00000') {
        this.tableData = data
      } else {
        this.$message.error(msg || '获取数据失败')
      }
    },
  },
  mounted () {
    this.getTableData()
  },
}
</script>

<style lang="less" scoped>
  .flex-a-c {
    display: flex;
    align-items: center;
  }
</style>
